exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-legal-terms-of-service-tsx": () => import("./../../../src/pages/legal/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-legal-terms-of-service-tsx" */)
}

